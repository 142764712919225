export const WORDS = [
	'pinis',
	'cutie',
	'sheep',
	'cocky',
	'mango',
	'peeps',
	'sonne',
	'horns',
	'fluff',
	'broth',
	'stink',
	'memes',
	'farts',
	'kween',
	'silly',
	'metal',
	'comfy',
	'plant',
	'maaah',
	'loved',
	'super',
	'anime',
	'vtuba',
	'feets',
	'foots',
	'wurst',
	'sissi',
	'daddy',
	'mommy',
	'sorry',
	'clips',
	'simps',
	'queen',
	'horor',
	'stein',
	'sothi',
	'ranch',
	'cocks',
	'model',
	'sleep',
	'waifu',
	'dutch',
	'sosig',
	'frens'
]
